<template>
    <form autocomplete="off">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row g-3">
            <div class="col-md-6">
                <label for="inputModel">
                    Model <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.model"
                :class="{ 'is-invalid': v$.data.model.$error }"
                id="inputModel" name="model" class="form-control"
                placeholder="Model" />
                <div v-for="(item, index) in v$.data.model.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">Category</label>
                <select @change="categoryChange($event)" class="form-select" v-model="data.category_id"
                name="category_id" placeholder="--select type--">
                    <option disabled value="">--select type--</option>
                    <option v-for="category in categories" :key="category.id"
                        :value="category.id">{{category.title}}</option>
                </select>
                <div v-for="(item, index) in v$.data.category_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">Make</label>
                <select class="form-select" v-model="data.brand_id"
                :class="{ 'is-invalid': v$.data.brand_id.$error }"
                name="type" placeholder="--select make--">
                    <option disabled value="">--select make--</option>
                    <option v-for="brand in brands" :value="brand.id" 
                    :key="brand.id">{{brand.title}}</option>
                </select>
                <div v-for="(item, index) in v$.data.brand_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            
            <div class="col-md-6">
                <label class="form-label">
                    Repair deductible <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.repair_deductible" class="form-control"
                name="repair_deductible" :class="{ 'is-invalid': v$.data.repair_deductible.$error }" placeholder="Enter repair deductible" />
                <div v-for="(item, index) in v$.data.repair_deductible.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Replacement cost <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.replacement_cost" class="form-control"
                name="replacement_cost" :class="{ 'is-invalid': v$.data.replacement_cost.$error }" placeholder="Enter replacement cost" />
                <div v-for="(item, index) in v$.data.replacement_cost.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Replacement deductible <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.replacement_deductible" class="form-control"
                name="replacement_deductible" :class="{ 'is-invalid': v$.data.replacement_deductible.$error }" placeholder="Enter replacement deductible" />
                <div v-for="(item, index) in v$.data.replacement_deductible.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-12">
                <label class="form-label">
                    Screen replacement deductible <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.screen_replacement_deductible" class="form-control"
                name="screen_replacement_deductible" :class="{ 'is-invalid': v$.data.screen_replacement_deductible.$error }" placeholder="Enter replacement deductible" />
                <div v-for="(item, index) in v$.data.screen_replacement_deductible.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Yearly coverage</label>
                <select class="form-select" v-model="data.annual_coverage_id"
                name="annual_coverage_id" placeholder="--select coverage--" 
                :class="{ 'is-invalid': v$.data.annual_coverage_id.$error}">
                    <option disabled value="">--select coverage--</option>
                    <option v-for="(coverage, cIndex) in yearlyCoverage"
                        :key="cIndex" :value="coverage.id">{{coverage.name}}</option>
                </select>
                <div v-for="(item, index) in v$.data.annual_coverage_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Monthly coverage</label>
                <select class="form-select" v-model="data.monthly_coverage_id"
                name="monthly_coverage_id" placeholder="--select coverage--" 
                :class="{ 'is-invalid': v$.data.monthly_coverage_id.$error}">
                    <option disabled value="">--select coverage--</option>
                    <option v-for="(coverage, cIndex) in monthlyCoverage"
                        :key="cIndex" :value="coverage.id">{{coverage.name}}</option>
                </select>
                <div v-for="(item, index) in v$.data.monthly_coverage_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="mt-2">
                    <vue-dropzone id="deviceUploadDrop" ref="deviceUploadDrop"
                    :use-custom-slot="true" :maxFiles="1">
                    <div class="needsclick m-0">
                        <span class="bx bx-cloud-upload fs-1"></span>
                        <h6 class="mb-1"> {{editMode?'Add file to update':'Device image'}}</h6>
                        <span class="text-muted fs-13">
                            (Image file is recommended (130x130 pixels))
                        </span>
                    </div>
                    </vue-dropzone>
                </div>
            </div>
       
            <div class="col-12">
                <div class="d-flex mt-4 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue';
import VueDropzone from "@/components/VueDropzone.vue";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        VueDropzone,
        IsLoading
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            isLoading: false,
            data: {
                model: "",
                category_id: "",
                brand_id: "",
                annual_coverage_id: '',
                monthly_coverage_id: '',
                repair_deductible: null,
                replacement_cost: null,
                replacement_deductible: null,
                screen_replacement_deductible: null,
                sort_order: 1,
            },
            category: null
        }
    },
    validations: {
        data:{
            category_id: {
                required: helpers.withMessage("The category is required", required),
            },
            brand_id: {
                required: helpers.withMessage("Make is required", required),
            },
            model: {
                required: helpers.withMessage("Model is required", required),
            },
            annual_coverage_id: {
                required: helpers.withMessage("annual coverage is required", required),
            },
            monthly_coverage_id: {
                required: helpers.withMessage("monthly coverage is required", required),
            },
            repair_deductible: {
                required: helpers.withMessage("Price is required", required),
            },
            replacement_cost: {
                required: helpers.withMessage("Price is required", required),
            },
            replacement_deductible: {
                required: helpers.withMessage("Price is required", required),
            },
            screen_replacement_deductible: {
                required: helpers.withMessage("Price is required", required),
            },
        }
    },
    computed:{
        categories(){
            return this.$store.state.category.list
        },
        brands(){
            return this.categories.reduce((acc, type) => {
                if (type.id === this.data.category_id) {
                    return acc.concat(type.brands);
                }
                return acc;
            }, []);
        },
        coverages(){
            return this.category ? this.category.coverages : [];
        },
        yearlyCoverage(){
            return this.coverages.filter((i) => i.plan_interval == 'year')
        },
        monthlyCoverage(){
            return this.coverages.filter((i) => i.plan_interval == 'month')
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                if(!this.collectFiles().length) {  this.alertError('Image file is required'); return  }
                const formData = new FormData()
                formData.append('image_file', this.collectFiles()[0])
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/devices/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("device/ADD_DEVICE", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                if(this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/devices/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit("updateItem", response.data.data)
                            // this.$store.commit("device/UPDATE_DEVICE", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        categoryChange(e){
            this.data.brand_id = this.data.annual_coverage_id = this.data.monthly_coverage_id = '';
            this.fetchCategory()
        },
        fetchCategory(){
            this.isLoading = true;
            this.$http.get(`/categories/${this.data.category_id}/show`)
            .then((response) => {
                this.isLoading = false;
                if(response.data.success){
                    this.category = response.data.data
                }
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.deviceUploadDrop){
                arrafile = [...this.$refs.deviceUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        },
        getOptionData(){
            this.isLoading = true;
            this.$store.dispatch("category/fetchItems")
            .then(response => this.isLoading = false );
        },
    },
    mounted(){
        this.getOptionData()
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
            this.fetchCategory()
        }
    }
}
</script>