<template>
<div>
  <div v-if="!isLoading" class="row mb-5 gy-2">
    <div class="col-12">
      <h5 class="mb-3">View device</h5>
      <div v-if="device">
        <div class="card card-height-100">
          <div class="card-body">
            <div class="row gy-3 justify-content-between align-items-start">
              <div class="col-10">
                <div class="mb-4 d-sm-flex align-items-center justify-content-start">
                  <div class="me-sm-3">
                    <img v-if="device.image" style="width:3rem;" :src="absoluteUrl(device.image)" />
                    <div v-else class="avatar img-thumbnail me-2 flex-shrink-0">
                      <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                        {{device.model.charAt(0).toUpperCase()}}
                      </div>
                    </div>
                  </div> 
                  <div class="">
                    <p class="mb-1"><strong>Model:</strong> {{device.model}}</p>
                    <div class="">
                      <span v-if="device.brand" class="me-3"> <strong>Make: </strong> {{device.brand.title}}</span>
                      <span v-if="device.category"> <strong>Category: </strong> {{device.category.title}}</span>
                    </div>
                  </div>
              </div>
                <div class="mb-4"> 
                  <p class="mb-1"><strong>${{device.repair_deductible}}</strong> Repair deductible </p>
                  <p class="mb-1"><strong>${{device.replacement_cost}}</strong> Replacement cost </p>
                  <p class="mb-1"><strong>${{device.replacement_deductible}}</strong> Replacement deductible </p>
                  <p class="mb-0"><strong>${{device.screen_replacement_deductible}}</strong> Screen replacement deductible </p>
                </div>
                <div class="mb-4"> 
                  <p class="mb-1 small" v-if="device.annual_coverage"><strong>Yearly Coverage:</strong><br> {{device.annual_coverage.name}} - ${{device.annual_coverage.price}}</p>
                  <p class="mb-0 small" v-if="device.monthly_coverage"><strong>Monthly Coverage:</strong><br> {{device.monthly_coverage.name}} - ${{device.monthly_coverage.price}}</p>
                </div>
              </div>
              <div class="col-2 text-end">
                <div class="dropdown no-arrow">
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a @click.prevent="popupModalShow = true" class="dropdown-item" href="javascript:void(0)" >
                      Update device </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-3">
          <div class="col-lg-6">
            <div class="card">
              <div class="card-header d-flex align-items-center justify-content-between">
                <h6 class="card-header-title m-0">Repair problems</h6>
                <button @click="popupModalRepairs = true" type="button" class="btn btn-sm btn-primary">Manage <span class="d-none d-sm-inline">repairs</span></button>
              </div>
              <div class="card-body">
                <ol>
                  <li v-for="repair in device.repairs" :key="repair.id">
                    {{ repair.title }} - ${{ repair.price }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <div class="card-header d-flex align-items-center justify-content-between">
                <h6 class="card-header-title m-0">Device attributes</h6>
                <button @click="popupModalAttributes = true" type="button" class="btn btn-sm btn-primary">Manage <span class="d-none d-sm-inline">attributes</span></button>
              </div>
              <div class="card-body">
                <ol>
                  <li v-for="attribute in device.attributes" :key="attribute.id" class="mb-2">
                    <p class="fw-bold mb-1">{{ attribute.name }}:</p>
                    <div>
                      <span v-for="value in attribute.values" :key="value.id"
                        class="badge bg-primary me-1"> {{value.name}}</span>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal size="lg" v-model="popupModalShow" body-class="loading-viewport" hide-footer centered
    title="Update device"
    title-class="fs-6" @hide="initializeAll()">
    <device-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editMode="true" :editItem="device" @updateItem="updateItem($event)" />
  </b-modal>
  <b-modal fullscreen v-model="popupModalAttributes" body-class="loading-viewport" hide-footer centered
    title="Manage Device attributes"
    title-class="fs-6" @hide="initializeAll()">
    <device-attributes-form v-if="popupModalAttributes" @closeMe="initializeAll()"
    :editMode="true" :editItem="device" @updateItem="updateItem($event)" />
  </b-modal>
  <b-modal fullscreen v-model="popupModalRepairs" body-class="loading-viewport" hide-footer centered
    title="Manage device repairs"
    title-class="fs-6" @hide="initializeAll()">
    <device-repairs-form v-if="popupModalRepairs" @closeMe="initializeAll()"
    :editMode="true" :editItem="device" @updateItem="updateItem($event)" />
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import DeviceForm from '@/components/forms/DeviceForm.vue'
import DeviceAttributesForm from '@/components/forms/DeviceAttributesForm.vue'
import DeviceRepairsForm from '@/components/forms/DeviceRepairsForm.vue'

export default {
  components: { IsLoading, DeviceForm,
    DeviceAttributesForm,
    DeviceRepairsForm 
  },
  data(){
    return{
      isLoading: false,
      device: null,
      popupModalShow: false,
      popupModalAttributes: false,
      popupModalRepairs: false,
    }
  },
  methods:{
    updateItem(item){
      Object.assign(this.device, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/devices/${this.$route.params.deviceId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.device = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    initializeAll(){
      this.popupModalShow = false;
      this.popupModalAttributes = false;
      this.popupModalRepairs = false;
    },
  },
  created(){
    this.fetchItem()
  }
}
</script>

<style>

</style>